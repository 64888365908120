import type React from 'react'

interface AnimatedHamburgerButtonProps {
	isOpen: boolean
	toggle: () => void
}

export const AnimatedHamburgerButton: React.FC<AnimatedHamburgerButtonProps> = ({ isOpen, toggle }) => {
	return (
		<button
			type='button'
			onClick={toggle}
			className='relative h-6 w-6 rounded bg-transparent focus:outline-none md:hidden'
			aria-label={isOpen ? 'Close menu' : 'Open menu'}
		>
			{/* Top Bar */}
			<span
				className={`absolute left-0 right-0 h-[2px] rounded bg-gray-800 transition-transform duration-500 ${isOpen ? 'top-1/2 rotate-45' : 'top-1/4 rotate-0'}`}
			/>
			{/* Middle Bar */}
			<span
				className={`absolute left-0 right-0 h-[2px] rounded bg-gray-800 transition-opacity duration-500 ${isOpen ? 'opacity-0' : 'top-1/2 opacity-100'}`}
			/>
			{/* Bottom Bar */}
			<span
				className={`absolute left-0 right-0 h-[2px] rounded bg-gray-800 transition-transform duration-500 ${isOpen ? 'top-1/2 -rotate-45' : 'top-3/4 rotate-0'}`}
			/>
		</button>
	)
}
